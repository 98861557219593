<template>
    <div class="design-page">
        <h2 class="text-center mt-3"> {{ $t('express-design') }}</h2>
        <div class="d-flex align-items-center justify-content-center px-5 py-3">
            <div class="row align-items-center justify-content-center h-100">
                <div class="col-lg-6">
                    <p>{{ $t('design-express-one') }}</p>
                    <p>{{ $t('design-express-two') }}</p>
                    <ul class="mt-3">
                        <li class="mb-2">{{ $t('design-express-three') }}</li>
                        <li class="mb-2">{{ $t('design-express-four') }}</li>
                        <li class="mb-2">{{ $t('design-express-five') }}</li>
                        <li class="mb-2">{{ $t('design-express-six') }}</li>
                        <li class="mb-2">{{ $t('design-express-seven') }}</li>
                    </ul>
                    <p>{{ $t('design-express-eight') }}</p>
                </div>
                <div>
                    <section class="form-section">
                        <div class="form-container">
                            <form @submit.prevent="getFormData" class="custom-form">
                                <div class="form-group">
                                    <label class="form-label">{{ $t('design-form-label-one') }}</label>
                                    <input v-model="formData.brand" type="text" class="form-input" />
                                </div>
                                <div class="form-group">
                                    <label class="form-label">{{ $t('design-form-label-two') }}</label>
                                    <textarea v-model="formData.aboutCompany" class="form-textarea"></textarea>
                                </div>
                                <div class="form-group">
                                    <label class="form-label">{{ $t('design-form-label-three') }}</label>
                                    <input v-model="formData.name" type="text" class="form-input" />
                                </div>
                                <div class="form-group">
                                    <label class="form-label">{{ $t('design-form-label-four') }}</label>
                                    <input v-model="formData.email" type="email" class="form-input" />
                                </div>
                                <div class="form-group">
                                    <label class="form-label">{{ $t('design-form-label-five') }}</label>
                                    <input v-model="formData.checkboxChecked" type="checkbox" id="checkbox"
                                        class="form-checkbox">
                                    <label for="checkbox" class="form-checkbox-label"> {{ $t('design-form-label-six') }}
                                        <em class="text-muted"> + 2,000 RON </em>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="submit-button">{{ $t('designForm_8') }}</button>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from "@/firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import comp from "../comp.json";

export default {
    data() {
        return {
            data: 'Studio',
            comp: comp,
            formData: {
                brand: "",
                aboutCompany: "",
                name: "",
                email: "",
                checkboxChecked: false
            },
        };
    },
    methods: {
        async getFormData() {
            try {
                await addDoc(collection(db, "design_express"), {
                    brand: this.formData.brand,
                    aboutCompany: this.formData.aboutCompany,
                    name: this.formData.name,
                    email: this.formData.email,
                    checkboxChecked: this.formData.checkboxChecked,
                    createdAt: serverTimestamp(),
                    status: "Pending"
                });
                alert(this.$t('success_msg'));
                this.formData = {
                    brand: "",
                    aboutCompany: "",
                    name: "",
                    email: "",
                    checkboxChecked: false
                };
            } catch (error) {
                console.error(this.$t('error_msg'), error);
                alert(this.$t('error_msg_text'));
            }
        },
    },
};
</script>