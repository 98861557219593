<template>
    <div class="details-2">
        <div class="container-fluid">
            <div class="row">
                <div class="first-child-px-0">
                    <div class="w-100">
                        <img src="@/assets/images/mm/mm_top.png" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> <b> MM Parquet is a well-established company that
                                specializes in providing high-quality flooring solutions for residential and commercial
                                clients.
                                With years of experience in the industry, the company has built a reputation for delivering
                                top-notch services that meet the needs and expectations of its clients.</b></p>
                    </div>
                </div>

                <div class="col-12 first-child-px-0">
                    <div class="w-100">
                        <img src="@/assets/images/mm/mm_002.jpg" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> <b> One of the key elements of the new visual identity was the
                                logo,
                                which we designed to be simple yet distinctive. The logo featured an abstract representation
                                of
                                a parquet floor, with a clean and modern typeface that conveyed the company's
                                professionalism
                                and reliability. The color scheme was carefully selected to reflect the warmth and natural
                                beauty of wood, while the typography was chosen for its readability and versatility.</b>
                        </p>
                    </div>
                </div>

                <div class="col-12 px-0">
                    <Carousel :items-to-show="1" :wrap-around="true" :breakpoints="breakpoints">
                        <Slide :key="1">
                            <div class="carousel__item">
                                <img src="@/assets/images/mm/mm_007.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="2">
                            <div class="carousel__item">
                                <img src="@/assets/images/mm/mm_006.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="3">
                            <div class="carousel__item">
                                <img src="@/assets/images/mm/mm_005.jpg" alt="">
                            </div>
                        </Slide>
                        <template #addons>
                            <Navigation />
                        </template>
                    </Carousel>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> <b> As a branding and visual identity, our goal
                                was
                                to
                                create a brand image that would reflect the company's values and expertise. We worked
                                closely
                                with the company's team to understand their vision and mission, and we conducted
                                research to gain insights into the target audience's preferences and expectations. Using
                                this
                                information, we developed a visual identity system that included a new logo, color scheme,
                                typography, and imagery.</b>
                        </p>
                    </div>
                </div>

                <div class="col-12 first-child-px-0">
                    <div class="w-100">
                        <img src="@/assets/images/mm/identica.jpg" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5">
                        <div class="col-md-6 text-paragraph">
                            <p class="mb-3"> <b> Scope of work </b>
                            </p>
                            <p> Branding, Visual Identity </p>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-12 pb-3 d-flex align-items-end bg-image-mm">
                    <ul class="mb-4">
                        <li> <b> Title: </b> Act. | Arta care transformă</li>
                        <li> <b> Client: </b> AMiCUS, Cluj-Napoca </li>
                        <li> <b> Year: </b> 2022 </li>
                        <li> <b> Tags: </b> Branding, Visual Identity </li>
                        <li><b>Design: </b> David Ciutan</li>
                    </ul>
                </div> -->

                <div class="w-100">
                    <img src="@/assets/images/mm/mm_bot.png" alt="">
                </div>

                <div class="col-12 pb-4 py-4 d-flex align-items-end">
                    <ul class="mb-3">
                        <li> <b> Title: </b> MM Parquet </li>
                        <li> <b> Client: </b> MM Parquet SRL </li>
                        <li> <b> Year: </b> 2023 </li>
                        <li> <b> Tags: </b> Branding, Visual Identity </li>
                        <li><b>Design: </b> David Ciutan</li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";

export default {
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            breakpoints: {
                // carousel breakponits 576px and up
                576: {
                    itemsToShow: 2,
                },
            },
        };
    },
};
</script>