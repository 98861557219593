<template>
  <div class="fade-in">
    <div class="work-page">
      <div class="container-fluid px-4 px-md-3 pt-4">
        <div class="row">

          <div class="col-sm-4">
            <router-link to="/coneon">
              <div class="portfolio-item">
                <img src="@/assets/images/coneon/cover.jpg" alt="">
              </div>
              <div class="portfolio-item-title">
                <h4 class="black"> Coneon | Branding </h4>
              </div>
            </router-link>
          </div>

          <div class="col-sm-4">
            <router-link to="/lectin">
              <div class="portfolio-item">
                <img src="@/assets/images/lectin/cover.jpg" alt="">
              </div>
              <div class="portfolio-item-title">
                <h4 class="black"> LEC.Tin | Digital design, Web platform </h4>
              </div>
            </router-link>
          </div>

          <div class="col-sm-4">
            <router-link to="/act">
              <div class="portfolio-item">
                <img src="@/assets/images/act/act_001.jpg" alt="">
              </div>
              <div class="portfolio-item-title">
                <h4 class="black"> Act. | Visual identity </h4>
              </div>
            </router-link>
          </div>

          <div class="col-sm-4">
            <router-link to="/mm-parquet">
              <div class="portfolio-item">
                <img src="@/assets/images/mm/mm_001.jpg" alt="">
              </div>
              <div class="portfolio-item-title">
                <h4 class="black"> MM Parquet | Branding</h4>
              </div>
            </router-link>
          </div>

          <div class="col-sm-4">
            <router-link to="/alerg-pentru-mama">
              <div class="portfolio-item">
                <img src="@/assets/images/apm/apm_007.jpg" alt="">
              </div>
              <div class="portfolio-item-title">
                <h4 class="black"> Alerg pentru mama | Visual identity </h4>
              </div>
            </router-link>
          </div>

          <div class="col-sm-4">
            <router-link to="/codesync">
              <div class="portfolio-item">
                <img src="@/assets/images/cs/cs_001.jpg" alt="">
              </div>
              <div class="portfolio-item-title">
                <h4 class="black"> Codesync | Branding </h4>
              </div>
            </router-link>
          </div>
        </div>


        <!-- <div v-for="n in data" :key="n.id" class="col-sm-6">
            <span v-if="n.type == 1">
              <router-link :to="'/work/details/' + n.id">
                <div class="portfolio-item">
                  <video autoplay muted loop id="myVideo" v-if="n.video"
                    :src="require(`@/assets/videos/${n.video}`)"></video>
                  <img v-else :src="require(`@/assets/images/${n.cover}`)" alt="" />
                </div>

                <div class="portfolio-item-title">
                  <h4 class="black">{{ n.name }}</h4>
                </div>
              </router-link>
            </span>
            <span v-else>
              <router-link :to="'/' + n.id">
                <div class="portfolio-item">
                  <video autoplay muted loop id="myVideo" v-if="n.video"
                    :src="require(`@/assets/videos/${n.video}`)"></video>
                  <img v-else :src="require(`@/assets/images/act/${n.image}`)" alt="" />
                </div>

                <div class="portfolio-item-title">
                  <h4 class="black">{{ n.name }}</h4>
                </div>
              </router-link>
            </span>
          </div> -->

      </div>
    </div>
  </div>
  <Request-Design />
</template>

<script>
import RequestDesign from "@/components/RequestDesign.vue";
import data from "../data.json";
export default {
  components: {
    RequestDesign,
  },
  data() {
    return {
      data: data,
    };
  },
};
</script>
