<template>
  <div class="details-page">
    <div class="container-fluid px-0 ps-lg-4 pe-lg-3 pt-5">
      <div class="row justify-content-center justify-content-lg-start mx-0">
        <div class="w-100">
          <div class="portfolio-item-title py-3">
            <h4 class="black">{{ selectedobj.name }}</h4>
          </div>
          <div class="portfolio-item w-100 abc">
            <img :src="require(`@/assets/images/${selectedobj.image}`)" alt="" />
          </div>

          <div class="d-flex justify-content-center py-5">
            <p class="col-md-6 text-paragraph">{{ selectedobj.description1 }}</p>
          </div>

          <!-- <div class="col-lg-6 py-5 my-lg-5">
            <p>
              {{ selectedobj.description1 }}
            </p>
            <p>
              {{ selectedobj.description2 }}
            </p>
          </div> -->
        </div>
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="portfolio-details-image">
              <img :src="require(`@/assets/images/${selectedobj.image2}`)" alt="" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="portfolio-details-image">
              <img :src="require(`@/assets/images/${selectedobj.image3}`)" alt="" />
            </div>
          </div>
        </div>
        <div class="col-lg-5 py-5 my-lg-5">
          <p class="ps-6">
            {{ selectedobj.descriptioin3 }}
          </p>
        </div>
        <div class="col-lg-11">
          <div class="portfolio-item w-100">
            <img :src="require(`@/assets/images/${selectedobj.image4}`)" alt="" />
          </div>
        </div>
        <div class="col-lg-5 py-5">
          <ul>
            <li>Title: {{ selectedobj.title }}</li>
            <li>Category: {{ selectedobj.category }}</li>
            <li>Year: {{ selectedobj.year }}</li>
            <li>Design: {{ selectedobj.design }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <Request-Design />
</template>

<script>
import RequestDesign from "@/components/RequestDesign.vue";
import propertyData from "../data.json"
export default {
  components: {
    RequestDesign,
  },
  data() {
    return {
      data: propertyData,
      selectedobj: propertyData[0]
    }
  },
  mounted() {
    this.selectedobj = this.data.filter((x) =>
      x.id == parseInt(this.$route.params.id)
    )[0]
  },
};
</script>