<template>
  <footer class="d-flex align-items-center">
    <div class="d-flex justify-content-center w-100">
      <span style="text-align: center;"> &#169; 2022-{{ year }} | Artonion Design Studio <br>
        <a href="mailto: contact@artonion.ro" class="text-decoration-underline"> contact@artonion.ro </a> </span>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>