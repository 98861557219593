// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBU3NxXlLJeFQBrxvpwWH9qfdy-Wy-30GA",
    authDomain: "artonion-f2d1b.firebaseapp.com",
    projectId: "artonion-f2d1b",
    storageBucket: "artonion-f2d1b.appspot.com",
    messagingSenderId: "396341802064",
    appId: "1:396341802064:web:46f2efc85a870d8c324d55",
    measurementId: "G-W3J4S8VBT4"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };