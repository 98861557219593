<template>
  <nav class="header d-flex align-items-center">
    <div class="container-fluid pt-3 pb-2">
      <div class="text-center mb-3">
        <router-link class="not" to="/"> <img class="logo" src="@/assets/logo.png" alt=""> </router-link>
      </div>
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="search-icon">
          <img src="@/assets/favicon.png" />
        </div>

        <div class="">
          <ul class="d-flex p-0 mb-0">
            <li><router-link to="/work"> {{ $t('work') }} </router-link></li>
            <li><router-link to="/about"> {{ $t('about') }} </router-link></li>
            <li><router-link to="/design-express"> {{ $t('express-design') }} </router-link></li>
            <li><router-link to="/contact-us"> {{ $t('contact') }}</router-link></li>
          </ul>
        </div>
        <div>
          <div class="text-right" @click="toggleLanguage"> {{ language === 'en' ?
            'EN' : 'RO'
            }} </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      language: 'en'
    };
  },
  methods: {
    toggleLanguage() {
      if (this.language === 'en') {
        this.language = 'ro';
      } else {
        this.language = 'en';
      }
      this.$i18n.locale = this.language;
    }
  }
};
</script>