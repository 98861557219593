import { createRouter, createWebHistory } from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import DefaultView from "../views/DefaultView.vue";
import WorkView from "../views/WorkView.vue";
import AboutView from "../views/AboutView.vue";
import ContactUs from "../views/ContactUs.vue";
import DesignExpress from "../views/DesignExpress.vue"
import details from "../views/details.vue";
import details2 from "../views/details-2.vue";

import Act from "../views/projects/Act.vue";
import Coneon from "../views/projects/Coneon.vue";
import Parquet from "../views/projects/Mm.vue";
import Alerg from "../views/projects/Apm.vue";
import Codesync from "../views/projects/Codesync.vue";
import Lectin from "../views/projects/Lectin.vue";

import NotFound from "../views/NotFound.vue";

const routes = [
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "",
        name: "DefaultView",
        component: DefaultView,
        meta: {
          title: "Home",
          description: "Welcome to Artonion Design Studio, your destination for exceptional design solutions."
        },
      },
      {
        path: "work",
        name: "Work",
        component: WorkView,
        meta: {
          title: "Work",
          description: "Explore our portfolio of outstanding design projects."
        },
      },
      {
        path: "about",
        name: "about",
        component: AboutView,
        meta: {
          title: "About",
          description: "Learn more about Artonion Design Studio and our team."
        },
      },
      {
        path: "design-express",
        name: "DesignExpress",
        component: DesignExpress,
        meta: { title: "Design Express" },
      },
      {
        path: "contact-us",
        name: "ContactUs",
        component: ContactUs,
        meta: {
          title: "Contact",
          description: "Get in touch with Artonion Design Studio."
        },
      },
      {
        path: "work/details/:id",
        name: "details",
        component: details,
        meta: { title: "Details" },
      },
      {
        path: "work/details-2/:id",
        name: "details2",
        component: details2,
        meta: { title: "Details-2" },
      },
      {
        path: "act",
        name: "act",
        component: Act,
        meta: { title: "Act" },
      },
      {
        path: "coneon",
        name: "coneon",
        component: Coneon,
        meta: { title: "Coneon" },
      },
      {
        path: "mm-parquet",
        name: "mm-parquet",
        component: Parquet,
        meta: { title: "MM-Parquet" },
      },
      {
        path: "alerg-pentru-mama",
        name: "alerge",
        component: Alerg,
        meta: { title: "Alerge pentru mama" },
      },
      {
        path: "codesync",
        name: "codesync",
        component: Codesync,
        meta: { title: "Codesync" },
      },
      {
        path: "lectin",
        name: "lectin",
        component: Lectin,
        meta: { title: "Lectin" },
      },
    ],
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;