<template>
    <div class="details-2">
        <div class="container-fluid">
            <div class="row">
                <div class="first-child-px-0">
                    <div class="w-100">
                        <img src="@/assets/images/lectin/cover.jpg" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5 md-3">
                        <p class="col-md-6 text-paragraph"> <b> Our design studio developed a cutting-edge web platform
                                tailored to meet the unique needs of Asociația "Exploratori pentru viitor!". This B2B
                                project serves as a powerful communication tool,
                                revolutionizing the way members interact and collaborate within the organization. </b> </p>
                    </div>
                </div>

                <div class="col-12">
                    <div class="w-100">
                        <img src="@/assets/images/lectin/stats.jpg" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> <b> Our team of talented designers meticulously crafted a
                                seamless and intuitive user interface (UI) for the LEC web platform. Leveraging our
                                expertise in
                                user experience (UX) design, we focused on delivering a visually captivating and
                                easy-to-navigate interface, ensuring a delightful user journey for every member of
                                association.
                            </b>
                        </p>
                    </div>
                </div>

                <div class="col-12 px-0">
                    <Carousel :items-to-show="1" :wrap-around="true" :breakpoints="breakpoints">
                        <Slide :key="1">
                            <div class="carousel__item">
                                <img src="@/assets/images/lectin/lectin_001.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="2">
                            <div class="carousel__item">
                                <img src="@/assets/images/lectin/lectin_002.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="3">
                            <div class="carousel__item">
                                <img src="@/assets/images/lectin/lectin_003.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="4">
                            <div class="carousel__item">
                                <img src="@/assets/images/lectin/lectin_005.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="5">
                            <div class="carousel__item">
                                <img src="@/assets/images/lectin/lectin_006.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="5">
                            <div class="carousel__item">
                                <img src="@/assets/images/lectin/lectin_004.jpg" alt="">
                            </div>
                        </Slide>
                        <template #addons>
                            <Navigation />
                        </template>
                    </Carousel>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> <b> In addition we provided IT solution that powers the web
                                platform. Our development team leveraged the latest technologies to build a scalable and
                                secure platform. The implementation of LEC has had a profound impact on the
                                Association workflow, revolutionizing their internal member engagement. By providing a
                                centralized hub for collaboration, the platform has facilitated seamless information
                                exchange, enabling members to work together towards the organization's mission more
                                effectively. As a result, the Association has experienced heightened
                                efficiency, improved decision-making, and strengthened relationships among its members. </b>
                        </p>
                    </div>
                </div>

                <div class="w-100">
                    <img src="@/assets/images/lectin/bot.jpg" alt="">
                </div>

                <div class="col-12 pb-4 d-flex align-items-end">
                    <ul class="mb-3">
                        <li> <b> Title: </b> LEC </li>
                        <li> <b> Client: </b> Asociația "Exploratori pentru viitor!" </li>
                        <li> <b> Year: </b> 2022 </li>
                        <li> <b> UI/UX: </b> David Ciutan</li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";

export default {
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            breakpoints: {
                // carousel breakponits 576px and up
                576: {
                    itemsToShow: 2,
                },
            },
        };
    },
};
</script>