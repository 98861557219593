export default {
  "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portofoliu"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despre noi"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacte"])},
  "about_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bun venit la Artonion, un studio de design creativ care combină arta și tehnologia pentru a da viață ideilor tale. Credem că designul este mai mult decât aspectul estetic, este despre crearea de experiențe semnificative care conectează oamenii cu brandurile."])},
  "about_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echipa noastră de designeri, dezvoltatori și artiști talentați lucrează împreună pentru a oferi soluții inovatoare adaptate nevoilor tale unice. Specializăm în branding, design web, design grafic, design UI/UX și marketing digital și suntem pasionați să ajutăm clienții noștri să-și atingă obiectivele."])},
  "about_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Artonion, abordăm fiecare proiect printr-un proces colaborativ. Lucrăm îndeaproape cu clienții noștri pentru a înțelege viziunea lor și pentru a crea un design care reflectă identitatea brandului lor. Credem că un design excelent începe cu o comunicare excelentă și suntem mândri de abilitatea noastră de a asculta, înțelege și livra."])},
  "contact_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informații de contact"])},
  "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitați un design"])},
  "designRequest_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitare design"])},
  "designRequest_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulțumim pentru interesul acordat serviciilor noastre! Portofoliul nostru include site-uri web, aplicații, campanii publicitare, logo-uri, afișe."])},
  "designRequest_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm să completați formularul de mai jos pentru a ne ajuta să înțelegem nevoile și preferințele dvs. în ceea ce privește designul. Vom analiza solicitarea dvs. și vă vom răspunde cât mai curând posibil cu o propunere."])},
  "designForm_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numele companiei"])},
  "designForm_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "designForm_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa de Email"])},
  "designForm_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O scurtă descriere a proiectului și bugetul aproximativ"])},
  "designForm_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Număr de telefon"])},
  "designForm_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Țară"])},
  "designForm_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume"])},
  "designForm_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimite"])},
  "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviciile noastre"])},
  "service_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design grafic"])},
  "service_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviciile noastre de design grafic includ crearea de identitate vizuală, materiale publicitare și grafică de înaltă calitate pentru a spori prezența vizuală a brandului dumneavoastră."])},
  "service_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materiale publicitare și branding"])},
  "service_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferim servicii complete de branding, inclusiv crearea materialelor publicitare și strategii eficiente pentru a vă promova afacerea."])},
  "service_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezvoltare identitate corporativă"])},
  "service_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferim servicii de dezvoltare a identității corporative, creând un aspect unitar și coeziv pentru afacerea dumneavoastră, de la logo-uri la materiale de birou."])},
  "service_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web design și interfață utilizator"])},
  "service_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creăm design-uri web moderne și interfețe de utilizator intuitive, optimizate pentru a oferi o experiență plăcută utilizatorilor pe site-ul dumneavoastră."])},
  "service_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programare web"])},
  "service_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echipa noastră de programatori vă poate ajuta să creați sau să îmbunătățiți site-ul dvs. web, oferind soluții personalizate și funcționalități avansate."])},
  "service_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotografie de brand"])},
  "service_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferim servicii profesionale de fotografie pentru produsele dumneavoastră, punându-le în cea mai bună lumină și evidențiind caracteristicile distinctive."])},
  "request_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitați un design"])},
  "request_design_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a începe, trebuie să vorbim. Spuneți-ne puțin despre proiectul dvs. și cum să luăm legătura."])},
  "error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare la trimiterea datelor"])},
  "success_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularul trimis cu succes!"])},
  "success_msg_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A apărut o eroare la trimiterea datelor. Vă rugăm să încercați din nou."])},
  "express-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Express design"])}
}