<template>
    <div class="details-2">
        <div class="container-fluid">
            <div class="row">
                <div class="first-child-px-0">
                    <div class="w-100">
                        <img src="@/assets/images/apm/apm_001.jpg" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> <b> We had the privilege of working with an inspiring NGO
                                AMiCUS,
                                Cluj-Napoca that organized the Charity Cross "Alerg pentru Mama". The event was designed to
                                raise awareness anв funds for mothers and children in need, and my role was to create a
                                compelling visual identity that would help promote the event and attract sponsors and
                                participants. </b> </p>
                    </div>
                </div>

                <div class="col-12 px-0">
                    <Carousel :items-to-show="1" :wrap-around="true" :breakpoints="breakpoints">
                        <Slide :key="1">
                            <div class="carousel__item">
                                <img src="@/assets/images/apm/apm_004.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="2">
                            <div class="carousel__item">
                                <img src="@/assets/images/apm/apm_005.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="3">
                            <div class="carousel__item">
                                <img src="@/assets/images/apm/apm_006.jpg" alt="">
                            </div>
                        </Slide>
                        <template #addons>
                            <Navigation />
                        </template>
                    </Carousel>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> <b> To create the visual identity, we first researched similar
                                events to gain insights into the visual language commonly used in the field. We then
                                worked closely with the AMiCUS Cluj-Napoca team to understand their goals, values, and
                                vision
                                for the event. Based on these inputs, we developed a visual identity system. </b>
                        </p>
                    </div>
                </div>

                <div class="col-12">
                    <div class="w-100">
                        <img src="@/assets/images/apm/apm_002.jpg" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> <b> The visual identity system we developed was used across a
                                range
                                of materials, including flyers, posters, banners, and social media graphics. The designs
                                were
                                tailored to the specific needs of each channel and audience, with the aim of maximizing
                                engagement and participation. The results were impressive, with the event attracting a large
                                number of sponsors and participants. </b>
                        </p>
                    </div>
                </div>

                <div class="w-100">
                    <img src="@/assets/images/apm/apm_003.jpg" alt="">
                </div>

                <div class="col-12 pb-4 py-4 d-flex align-items-end">
                    <ul class="mb-3">
                        <li> <b> Title: </b> Alerg pentru mama </li>
                        <li> <b> Client: </b> AMiCUS, Cluj-Napoca </li>
                        <li> <b> Year: </b> 2023 </li>
                        <li> <b> Design: </b> David Ciutan</li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";

export default {
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            breakpoints: {
                // carousel breakponits 576px and up
                576: {
                    itemsToShow: 2,
                },
            },
        };
    },
};
</script>