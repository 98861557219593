<template>
    <div class="details-2">
        <div class="container-fluid">
            <div class="row">
                <div class="first-child-px-0">
                    <div class="w-100 d-flex">
                        <img src="@/assets/images/cs/cs_000.png" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> We had the pleasure of working with Codesync, an IT
                            company that
                            specializes in software development and system integration services. Our task was to create a
                            brand and visual identity that would reflect the company's expertise, innovation, and
                            professionalism, and help them stand out in a crowded market.</p>
                    </div>
                </div>

                <div class="col-12 first-child-px-0">
                    <div class="w-100">
                        <img src="@/assets/images/cs/cs_003.jpg" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> The visual identity system we developed was used across a range
                            of materials, including business cards, letterheads, brochures, badges. The designs
                            were tailored to the specific needs of each channel and audience, with the aim of creating a
                            cohesive and impactful brand image. The results were impressive, with the new brand image
                            helping Codesync attract new clients and consolidate its position as a leading IT company.
                        </p>
                    </div>
                </div>

                <div class="col-12 first-child-px-0">
                    <div class="w-100">
                        <img src="@/assets/images/cs/cs_004.jpg" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> To create the brand and visual identity, we first conducted
                            research to gain insights into the company's target audience, competitors, and industry trends.
                            We then worked closely with the Codesync team to understand their vision, mission, and values,
                            and to identify their unique selling points and key differentiators. Based on this research, we
                            developed a brand strategy that focused on the company's expertise in software development and
                            system integration, and their commitment to delivering innovative solutions that meet the needs
                            of their clients.
                        </p>
                    </div>
                </div>


                <div class="w-100">
                    <img src="@/assets/images/cs/cs_005.jpg" alt="">
                </div>

                <div class="col-12 pb-4 py-4 d-flex align-items-end">
                    <ul class="mb-3">
                        <li> <b> Title: </b> Codesync </li>
                        <li> <b> Client: </b> Codesync Group</li>
                        <li> <b> Year: </b> 2023 </li>
                        <li> <b> Tags: </b> Branding, Visual Identity </li>
                        <li><b>Design: </b> David Ciutan</li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";

export default {
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            breakpoints: {
                // carousel breakponits 576px and up
                576: {
                    itemsToShow: 2,
                },
            },
        };
    },
};
</script>