<template>
    <div class="details-2">
        <div class="container-fluid">
            <div class="row">
                <div class="first-child-px-0">
                    <div class="w-100">
                        <img src="@/assets/images/act/top.png" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-4">
                        <p class="col-md-6 text-paragraph"> <b> Once upon a time, there was an AMiCUS student NGO.
                                They decided to organize a Festival of Creation - an event that would showcase the talents
                                of
                                artists, musicians, and performers from all over the Romania. The Festival of Creation had a
                                specific theme of ACT, which was born in 2020. However, due to the pandemic, it was not
                                possible
                                to organize the festival at that time. In 2022, a new team decided to revive the festival
                                with a
                                fresh spirit, making it a celebration of innovation, imagination, and action. Despite the
                                challenges of the previous year, the Festival of Creation was able to inspire and bring
                                people
                                together through the power of creativity. The festival promised to be a celebration of
                                imagination, dedicated to promoting creativity and artistic expression, and AMiCUS was
                                determined to make it a success.</b>
                        </p>
                    </div>
                </div>
                <div class="col-12 first-child-px-0">
                    <div class="w-100">
                        <img src="@/assets/images/act/act_001.jpg" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> <b> To bring their vision to life, AMiCUS reached out to
                                our team for support. They requested our assistance with the graphic design and visual
                                identity
                                of the festival, as well as the printing and marketing of promotional materials. We were
                                thrilled to be part of such an exciting project and quickly got to work. </b>
                        </p>
                    </div>
                </div>
                <div class="col-12 first-child-px-0">
                    <div class="w-100">
                        <img src="@/assets/images/act/act_002.jpg" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> <b>Our first step was to develop a comprehensive visual
                                identity for the festival. We created a logo and a color scheme that captured the spirit of
                                creativity and excitement that the festival embodied. We then designed promotional
                                materials,
                                including flyers, posters, and banners, that featured this visual identity and conveyed the
                                festival's key messages.</b>
                        </p>
                    </div>
                </div>
                <div class="col-12 px-0">
                    <Carousel :items-to-show="1" :wrap-around="true" :breakpoints="breakpoints">
                        <Slide :key="1">
                            <div class="carousel__item">
                                <img src="@/assets/images/act/act_003.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="2">
                            <div class="carousel__item">
                                <img src="@/assets/images/act/act_004.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="3">
                            <div class="carousel__item">
                                <img src="@/assets/images/act/act_005.jpg" alt="">
                            </div>
                        </Slide>
                        <template #addons>
                            <Navigation />
                        </template>
                    </Carousel>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> <b> We focused on developing the festival's social media
                                presence. We created engaging content, with detailed information about the festival's
                                schedule,
                                performers, and activities, regular updates on platforms such as Instagram and Facebook. By
                                doing so, we were able to effectively promote the Festival of Creation. </b>
                        </p>
                    </div>
                </div>

                <div class="col-12">
                    <div>
                        <img src="@/assets/images/act/act_006.jpg" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5">
                        <div class="col-md-8 text-paragraph">
                            <p class="mb-3"> <b> Scope of work</b></p>
                            <p>
                                Visual identity, Print Design, Merchandise, Marketing
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 pb-3 d-flex align-items-end bg-image text-white">
                    <ul class="mb-4">
                        <li> <b> Title: </b> Act. | Arta care transformă</li>
                        <li> <b> Client: </b> AMiCUS, Cluj-Napoca </li>
                        <li> <b> Year: </b> 2022 </li>
                        <li> <b> Design: </b> David Ciutan</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
export default {
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            breakpoints: {
                // carousel breakponits 576px and up
                576: {
                    itemsToShow: 2,
                },
            },

        };
    },
};
</script>
  