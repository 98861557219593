import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/styles/main.scss";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.js";
import i18n from "./i18n";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
createApp(App)
    .use(i18n)
    .use(store)
    .use(bootstrap)
    .use(router)
    .use(Carousel, Slide, Pagination, Navigation)
    .mount("#app");
