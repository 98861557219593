<template>
  <div class="details-2">
    <div class="container-fluid">
      <div class="row">
        <div class="first-child-px-0">
          <div class="w-100">
            <img :src="require(`@/assets/images/act/${selectedobj.image1}`)" alt="" />
          </div>
          <div class="d-flex justify-content-center py-5">
            <p class="col-md-6 text-paragraph">{{ selectedobj.text1 }}</p>
          </div>
        </div>
        <div class="col-12 first-child-px-0">
          <div class="w-100">
            <img :src="require(`@/assets/images/act/${selectedobj.image2}`)" alt="" />
          </div>
          <div class="d-flex justify-content-center py-5">
            <p class="col-md-6 text-paragraph">{{ selectedobj.text2 }}</p>
          </div>
        </div>
        <div class="col-12 first-child-px-0">
          <div class="w-100">
            <img :src="require(`@/assets/images/act/${selectedobj.image3}`)" alt="" />
          </div>
          <div class="d-flex justify-content-center py-5">
            <p class="col-md-6 text-paragraph">{{ selectedobj.text3 }}</p>
          </div>
        </div>
        <div class="col-12 px-0">
          <Carousel :items-to-show="1" :wrap-around="true" :breakpoints="breakpoints">
            <Slide :key="1">
              <div class="carousel__item">
                <img :src="require(`@/assets/images/carousel/${selectedobj.carouselimg1}`)" alt="img" />
              </div>
            </Slide>
            <Slide :key="2">
              <div class="carousel__item">
                <img :src="require(`@/assets/images/carousel/${selectedobj.carouselimg2}`)" alt="img" />
              </div>
            </Slide>
            <Slide :key="3">
              <div class="carousel__item">
                <img :src="require(`@/assets/images/carousel/${selectedobj.carouselimg3}`)" alt="img" />
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
          <div class="d-flex justify-content-center py-5">
            <p class="col-md-6 text-paragraph">{{ selectedobj.text4 }}</p>
          </div>
        </div>

        <div class="col-12">
          <div>
            <img :src="require(`@/assets/images/act/${selectedobj.image4}`)" alt="img" />
          </div>
          <div class="d-flex justify-content-center py-5">
            <div class="col-md-8 text-paragraph">
              <p class="mb-3"> <b> {{ selectedobj.text5 }} </b></p>
              <p>
                {{ selectedobj.text6 }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 pb-3 d-flex align-items-end bg-image text-white">
          <ul class="mb-4">
            <li>Title: {{ selectedobj.title }}</li>
            <li>Client: {{ selectedobj.Client }}</li>
            <li>Year: {{ selectedobj.year }}</li>
            <li>Tags: {{ selectedobj.Tags }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import propertyData from "../data.json";
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      data: propertyData,
      selectedobj: propertyData[2],
      breakpoints: {
        // carousel breakponits 576px and up
        576: {
          itemsToShow: 2,
        },
      },
      items: [
        {
          id: 1,
          src: require("@/assets/images/act/top.png"),
          sildeImg: require("@/assets/images/carousel/act_003.jpg"),
          text: "The MM Parquet logo features a simple yet elegant design that incorporates the company's name in a stylized font. The letter 'M' in the logo is designed to resemble a piece of parquet flooring, highlighting the company's expertise in this area. The logo's color scheme is a combination of earthy tones, such as brown and green, which convey a sense of natural beauty and durability.",
        },
        {
          id: 2,
          src: require("@/assets/images/act/act_001.jpg"),
          sildeImg: require("@/assets/images/carousel/act_004.jpg"),
          text: "The MM Parquet logo features a simple yet elegant design that incorporates the company's name in a stylized font. The letter 'M' in the logo is designed to resemble a piece of parquet flooring, highlighting the company's expertise in this area. The logo's color scheme is a combination of earthy tones, such as brown and green, which convey a sense of natural beauty and durability.",
        },
        {
          id: 3,
          src: require("@/assets/images/act/act_002.jpg"),
          sildeImg: require("@/assets/images/carousel/act_005.jpg"),
          text: "The MM Parquet logo features a simple yet elegant design that incorporates the company's name in a stylized font. The letter 'M' in the logo is designed to resemble a piece of parquet flooring, highlighting the company's expertise in this area. The logo's color scheme is a combination of earthy tones, such as brown and green, which convey a sense of natural beauty and durability.",
        },
      ],
    };
  },
  mounted() {
    this.selectedobj = this.data.filter(
      (x) => x.id == parseInt(this.$route.params.id)
    )[0];
  },
};
</script>
