<template>
  <router-view />
</template>

<script>
export default {
  watch: {
    '$route'() {
      this.updateMetaTags()
    }
  },
  mounted() {
    this.updateMetaTags()
  },
  methods: {
    updateMetaTags() {
      const pageTitle = `Artonion | ${this.$route.meta.title || 'My Website'}`
      const pageDescription = this.$route.meta.description || 'Welcome to Artonion Design Studio, your destination for exceptional design solutions.'

      // Обновление заголовка страницы
      document.title = pageTitle

      // Обновление мета-описания
      let metaDescription = document.querySelector('meta[name="description"]')
      if (metaDescription) {
        metaDescription.setAttribute('content', pageDescription)
      } else {
        metaDescription = document.createElement('meta')
        metaDescription.setAttribute('name', 'description')
        metaDescription.setAttribute('content', pageDescription)
        document.head.appendChild(metaDescription)
      }
    }
  }
}
</script>
