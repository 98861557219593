<template>
    <div class="details-2">
        <div class="container-fluid">
            <div class="row">
                <div class="first-child-px-0">
                    <div class="w-100">
                        <img src="@/assets/images/coneon/top_01.png" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-4">
                        <p class="col-md-6 text-paragraph"> <b> Welcome to the world of Coneon, where the art of tea is
                                embodied in every leaf and sip. Our brand is a celebration of simplicity, sophistication,
                                and the timeless allure of black and white. The iconic logo, a bold and captivating big
                                three, stands as a tribute to the three core elements that define Coneon: purity, quality,
                                and the pursuit of perfection.

                                Coneon's graphic design and branding are a visual symphony, harmonizing the stark elegance
                                of black with the pristine clarity of white. The minimalist color palette underscores our
                                commitment to letting the tea itself take center stage. As you explore our range, the big
                                three logo becomes a symbol of assurance, promising a triad of excellence in every tea
                                experience. </b>
                        </p>
                    </div>
                </div>
                <div class="col-12 first-child-px-0">
                    <div class="w-100">
                        <img src="@/assets/images/coneon/5.jpg" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> <b> The packaging is a study in understated luxury, with clean
                                lines and a contemporary aesthetic that mirrors the modern tea connoisseur's taste. The big
                                three logo is strategically placed, an emblem of distinction that whispers promises of a
                                refined tea journey within. </b>
                        </p>
                    </div>
                </div>
                <div class="col-12 first-child-px-0">
                    <div class="w-100">
                        <img src="@/assets/images/coneon/1.jpg" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> <b> At Coneon, we believe in the power of simplicity, letting
                                the quality of our teas speak for themselves. The big three logo encapsulates this ethos,
                                representing the trinity of taste, aroma, and tradition. It's a visual cue that invites you
                                to embark on a monochromatic voyage, where each cup unfolds a story of purity and
                                unparalleled flavor. </b>
                        </p>
                    </div>
                </div>
                <div class="col-12 px-0">
                    <Carousel :items-to-show="1" :wrap-around="true" :breakpoints="breakpoints">
                        <Slide :key="1">
                            <div class="carousel__item">
                                <img src="@/assets/images/coneon/8.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="2">
                            <div class="carousel__item">
                                <img src="@/assets/images/coneon/6.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="3">
                            <div class="carousel__item">
                                <img src="@/assets/images/coneon/3.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="4">
                            <div class="carousel__item">
                                <img src="@/assets/images/coneon/4.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="5">
                            <div class="carousel__item">
                                <img src="@/assets/images/coneon/7.jpg" alt="">
                            </div>
                        </Slide>
                        <Slide :key="6">
                            <div class="carousel__item">
                                <img src="@/assets/images/coneon/2.jpg" alt="">
                            </div>
                        </Slide>
                        <template #addons>
                            <Navigation />
                        </template>
                    </Carousel>
                    <div class="d-flex justify-content-center py-5">
                        <p class="col-md-6 text-paragraph"> <b> Indulge in the sophistication of Coneon, where black and
                                white become the canvas for an extraordinary tea experience. The big three logo is not just
                                an emblem; it's an invitation to savor the essence of tea in its purest form. </b>
                        </p>
                    </div>
                </div>

                <div class="col-12">
                    <div>
                        <img src="@/assets/images/coneon/12.jpg" alt="">
                    </div>
                    <div class="d-flex justify-content-center py-5">
                        <div class="col-md-8 text-paragraph">
                            <p class="mb-3"> <b> Scope of work</b></p>
                            <p>
                                Visual identity, Print Design, Merchandise, Marketing
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 pb-3 d-flex align-items-end text-white">
                    <ul class="mb-4">
                        <li> <b> Title: </b> Coneon | Tea</li>
                        <li> <b> Client: </b> Cleo & Co. Teas </li>
                        <li> <b> Year: </b> 2023 </li>
                        <li> <b> Design: </b> David Ciutan, Draci Grigori </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
export default {
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            breakpoints: {
                // carousel breakponits 576px and up
                576: {
                    itemsToShow: 2,
                },
            },

        };
    },
};
</script>
  