export default {
  "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "about_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Artonion, a creative design studio that combines art and technology to bring your ideas to life. We believe that design is more than just aesthetics, it's about creating meaningful experiences that connect people with brands."])},
  "about_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team of skilled designers, developers, and artists work together to deliver innovative solutions that are tailored to your unique needs. We specialize in branding, web design, graphic design, UI/UX design, and digital marketing, and we're passionate about helping our clients achieve their goals."])},
  "about_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Artonion, we take a collaborative approach to every project. We work closely with our clients to understand their vision and create a design that reflects their brand identity. We believe that great design starts with great communication, and we pride ourselves on our ability to listen, understand, and deliver."])},
  "contact_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
  "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a design"])},
  "designRequest_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Request"])},
  "designRequest_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your interest in our design services! Our portfolio includes websites, apps, advertising campaigns, logos, posters."])},
  "designRequest_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill out the form below to help us understand your design needs and preferences. We will review your request and get back to you as soon as possible with a proposal."])},
  "designForm_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
  "designForm_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "designForm_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "designForm_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A brief description of your project and approximative budget"])},
  "designForm_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
  "designForm_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "designForm_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "designForm_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our services"])},
  "service_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic Design"])},
  "service_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our graphic design services include creating visual identities, advertising materials, and high-quality graphics to enhance the visual presence of your brand."])},
  "service_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertising Materials and Branding"])},
  "service_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer comprehensive branding services, including the creation of advertising materials and effective strategies to promote your business."])},
  "service_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate Identity Development"])},
  "service_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer corporate identity development services, creating a unified and cohesive look for your business, from logos to office materials."])},
  "service_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Design and User Interface"])},
  "service_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We create modern web designs and intuitive user interfaces, optimized to provide a pleasant user experience on your website."])},
  "service_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Development"])},
  "service_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team of developers can help you create or enhance your website, providing customized solutions and advanced functionalities."])},
  "service_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand Photography"])},
  "service_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide professional product photography services, showcasing your products in the best light and highlighting their distinctive features."])},
  "request_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a design"])},
  "request_design_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To get started, we need to talk. So, tell us a little about your project and let us know how to get in touch."])},
  "error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error sending data"])},
  "success_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form submitted successfully!"])},
  "success_msg_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred when sending data. Please try again."])},
  "express-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Express design"])},
  "design-express-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some people have decided to open their own coffee shop, hair salon, or clothing drop-off point. We thought: will they ever come to us for a logo? They might, but the entry price is too high for beginners. So, most likely, they won’t come. And we decided to bridge this gap."])},
  "design-express-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For those we really like, we will create a logo and basic style over the weekend for 2,000 RON. This is several times less than the actual cost, so there are conditions:"])},
  "design-express-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the project must be small and poor (initially);"])},
  "design-express-four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["once a week we choose a startup for which we create a logo and style;"])},
  "design-express-five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment is made and a standard contract is signed;"])},
  "design-express-six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the option we choose is not subject to discussion;"])},
  "design-express-seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with the money received, we improve something in the studio — buy a new computer, camera, pool table, curtains, etc."])},
  "design-express-eight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Express Design” is not a way for a large company to cunningly get design from us cheaply. It is help for small and startup projects. If it turns out that an application under the guise of an individual was made in the interests of a large company, we will simply refund the money."])},
  "design-form-label-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
  "design-form-label-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About company"])},
  "design-form-label-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "design-form-label-four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "design-form-label-five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra option"])},
  "design-form-label-six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make two versions of the logo"])}
}